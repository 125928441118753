import { yupResolver } from "@hookform/resolvers/yup"
import {
  Box,
  Button,
  Divider,
  Flex,
  Image,
  Typography,
} from "@jewlr/storybook/core"
import { Input, Radio, Textarea } from "@jewlr/storybook/form"
import { Modal } from "@jewlr/storybook/modal"
import PropTypes from "prop-types"
import React from "react"
import { useForm, Controller } from "react-hook-form"
import styled from "styled-components"
import * as Yup from "yup"

import Slider from "dependencies/carousel"
import { jewlr_t } from "helpers/application"
import useWindowDimensions from "helpers/window-dimensions"

const SliderWrapper = styled(Slider)`
  margin-bottom: 40px;

  .slider-control-bottomcenter {
    margin-bottom: -34px;
  }
`

const PreviewDesc = styled(Typography)`
  color: ${(props) => props.theme.colors.grey[55]};
  font-size: 14px;
  font-weight: 300;
  line-height: 14px;
  text-align: center;
`

const PreviewBox = styled(Box)`
  @font-face {
    font-display: swap;
    font-family: "Oooh Baby";
    font-style: normal;
    font-weight: normal;
    src: url("${require("fonts/oooh-baby/oooh-baby-regular.ttf")}")
      format("truetype");
  }
  background: linear-gradient(
    117deg,
    #fff 6.22%,
    rgba(217, 217, 217, 0.19) 91.33%
  );
  border: 1px solid ${(props) => props.theme.colors.grey[10]};
  display: flex;
  flex-direction: column;
  font-family: "Oooh Baby", serif;
  font-size: 11px;
  height: 160px;
  justify-content: center;
  letter-spacing: -0.02em;
  line-height: 14px;
  margin-bottom: 10px;
  max-height: 160px;
  overflow: hidden;
  overflow-wrap: break-word;
  padding: 24px 20px;
  width: 240px;
`

const PreviewText = styled(Typography)`
  white-space: pre-wrap;
`

const StyledTextarea = styled(Textarea)`
  border: 1px solid ${(props) => props.theme.colors.grey[20]};
  color: ${(props) => props.theme.colors.grey[65]};
  padding: 12px 10px;
  resize: none;
  &:focus {
    border: 1px solid ${(props) => props.theme.colors.black};
    outline: none;
  }
`

const StyledButton = styled(Button).attrs((props) => ({
  height: "44px",
  width: { _: "100%", tablet: "300px" },
  ...props,
}))``

const CancelButton = styled(StyledButton).attrs((props) => ({
  bg: "white",
  color: "primary",
  variant: "text",
  ...props,
}))`
  order: 1;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
  ${(props) => props.theme.mediaQueries.tablet`
    border: 1px solid ${props.theme.colors.primary};
    order: 0;
    text-decoration: none;
  `}
`

const MAX_CHARS = 200
const MAX_LINES = 7

const schema = Yup.object().shape({
  message: Yup.string(),
  recipient: Yup.string().max(30, "Maximum 30 characters"),
  sender: Yup.string().max(30, "Maximum 30 characters"),
})

const GreetingCardModal = ({
  greetingCardProps,
  handleGreetingCard,
  onClose,
  selectedCardOption,
  show,
}) => {
  if (!selectedCardOption) return null
  const { isMobile } = useWindowDimensions()
  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    setError,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      message: greetingCardProps?.message || "",
      recipient: greetingCardProps?.recipient || "",
      sender: greetingCardProps?.sender || "",
    },
    mode: "onBlur",
    resolver: yupResolver(schema),
  })

  const validateMsg = (message) => {
    const rows = message.split("\n")
    const newRows = rows.length > MAX_LINES ? rows.slice(0, MAX_LINES) : rows
    return newRows.join("\n")
  }

  const handleChange = (e, setValue) => {
    const text = e.target.value
    const keycode = e.keycode || e.which || e.charCode
    const rows = text.split("\n")
    if (
      (rows.length >= MAX_LINES && keycode === 13) ||
      (text.length >= MAX_CHARS && ![8, 46].includes(keycode))
    ) {
      e.preventDefault()
    }
    setValue("message", validateMsg(text))
  }

  const handleDrop = (e, setValue) => {
    e.preventDefault()
    const text = e.dataTransfer.getData("text")
    setValue("message", validateMsg(text))
  }

  const sliderSettings = {
    arrows: false,
    frameAriaLabel: "Greeting card front and back view",
  }

  const onSubmit = async (data) => {
    try {
      await new Promise((res, rej) => {
        handleGreetingCard(
          { ...selectedCardOption, ...data },
          {
            onError: (err) => rej(err),
            onSuccess: () => {
              onClose()
              res()
            },
          }
        )
      })
    } catch (err) {
      setError("root.error", { message: err })
    }
  }

  const charsLeft = MAX_CHARS - watch("message")?.length
  const overLimit = charsLeft < 0

  return (
    <Modal
      contentStyle={{ pb: 2, pt: 0, px: { _: 2, desktop: 3 } }}
      headerType="inline"
      label={`Greeting Card ${jewlr_t("terms.Personalization")}`}
      modalType="slideup"
      onClose={onClose}
      show={show}
      width="712px"
      withBackButton={false}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        {isMobile ? (
          <SliderWrapper {...sliderSettings}>
            <Box>
              <Flex alignItems="center" flexDirection="column">
                <PreviewBox>
                  <PreviewText mb="1em">{watch("recipient")}</PreviewText>
                  <PreviewText>{watch("message")}</PreviewText>
                  <PreviewText mt="1em" textAlign="center">
                    {watch("sender")}
                  </PreviewText>
                </PreviewBox>
                <PreviewDesc>Inside of Greeting Card</PreviewDesc>
              </Flex>
            </Box>
            <Box>
              <Flex alignItems="center" flexDirection="column">
                <Image
                  alt="Front of greeting card"
                  maxHeight="160px"
                  mb="10px"
                  src={selectedCardOption.src_image_url}
                  width="240px"
                />
                <PreviewDesc>Front of Greeting Card</PreviewDesc>
              </Flex>
            </Box>
          </SliderWrapper>
        ) : (
          <Flex gap="20px" justifyContent="center" mb={3}>
            <Box>
              <Image
                alt="Front of greeting card"
                maxHeight="160px"
                mb="10px"
                src={selectedCardOption.src_image_url}
              />
              <PreviewDesc>Front</PreviewDesc>
            </Box>
            <Flex flexDirection="column">
              <PreviewBox>
                <PreviewText mb="1em">{watch("recipient")}</PreviewText>
                <PreviewText>{watch("message")}</PreviewText>
                <PreviewText mt="1em" textAlign="center">
                  {watch("sender")}
                </PreviewText>
              </PreviewBox>
              <PreviewDesc>Inside</PreviewDesc>
            </Flex>
          </Flex>
        )}
        <Flex flexDirection={{ _: "column", desktop: "row" }} gap="20px" mb={3}>
          <Box flex="1 1 50%">
            <Typography
              fontSize="18px"
              letterSpacing="-0.02em"
              lineHeight="22px"
              mb="18px"
            >
              Select a default message
            </Typography>
            <Box>
              {selectedCardOption.default_messages.map((message, i) => (
                <React.Fragment key={i}>
                  {i > 0 && <Divider bg="grey.20" my="18px" width="100px" />}
                  <Radio
                    checked={watch("message") === message}
                    containerStyle={{ alignItems: "flex-start" }}
                    label={message}
                    labelBoxStyle={{
                      fontSize: "14px",
                      fontWeight: "300",
                      lineHeight: "18px",
                      ml: "10px",
                      whiteSpace: "pre-wrap",
                    }}
                    name="default_message"
                    onChange={() => setValue("message", message)}
                    variant="check"
                  />
                </React.Fragment>
              ))}
            </Box>
          </Box>
          <Flex flex="1 1 50%" flexDirection="column">
            <Typography
              fontSize="16px"
              letterSpacing="-0.02em"
              lineHeight="22px"
              mb={{ _: "4px", desktop: "12px" }}
            >
              You can edit the default message or write your own.
            </Typography>
            <Flex
              gap="10px"
              mb={{ _: "30px", desktop: "12px" }}
              order={{ _: -1, desktop: 0 }}
            >
              <Box flex="1 1 50%">
                <Controller
                  control={control}
                  name="recipient"
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      error={error}
                      label="Recipient:"
                      placeholder="ie. To, Dear, Hey + Name"
                      {...field}
                    />
                  )}
                  rules={{ maxLength: "30" }}
                />
              </Box>
              <Box flex="1 1 50%">
                <Controller
                  control={control}
                  name="sender"
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      error={error}
                      label="Sender:"
                      placeholder="ie. Love, From + Name"
                      {...field}
                    />
                  )}
                  rules={{ maxLength: "30" }}
                />
              </Box>
            </Flex>
            <Controller
              control={control}
              name="message"
              render={({ field, fieldState: { error } }) => (
                <StyledTextarea
                  data-cy="cart-line-item-greeting-card-message"
                  error={error}
                  onDrop={(e) => handleDrop(e, setValue)}
                  onKeyDown={(e) => handleChange(e, setValue)}
                  onKeyUp={(e) => handleChange(e, setValue)}
                  onPaste={(e) => handleChange(e, setValue)}
                  rows="6"
                  {...field}
                />
              )}
              rules={{ maxLength: MAX_CHARS }}
            />
            <Typography
              color={overLimit ? "error" : "primary"}
              fontSize="12px"
              fontWeight="500"
              letterSpacing="-0.03em"
              lineHeight="12px"
              mt="10px"
            >
              <Typography as="span" color={overLimit ? "error" : "primary"}>
                {charsLeft}{" "}
                {overLimit ? "Characters Over Limit" : "Characters Left"}
              </Typography>{" "}
              &ndash; {MAX_CHARS} Characters Maximum
            </Typography>
          </Flex>
        </Flex>
        {errors?.root?.error && (
          <Typography color="error" my={1}>
            {errors.root.error.message}
          </Typography>
        )}
        <Flex
          flexDirection={{ _: "column", desktop: "row" }}
          gap="20px"
          justifyContent="center"
        >
          <CancelButton onClick={() => onClose()}>Cancel Message</CancelButton>
          <StyledButton
            disabled={overLimit}
            loading={isSubmitting}
            type="submit"
            variant="primary"
          >
            Save Message
          </StyledButton>
        </Flex>
      </form>
    </Modal>
  )
}

GreetingCardModal.propTypes = {
  greetingCardProps: PropTypes.object,
  handleGreetingCard: PropTypes.func,
  onClose: PropTypes.func,
  selectedCardOption: PropTypes.object,
  show: PropTypes.bool,
}

export default GreetingCardModal
